<template>
  <div class="wrap">
   <Loading v-show="isLoading"/>
   <div v-show="!isLoading">
    <div v-show='!isEmpty' class="wrap1">
      <!-- <van-tabs @click="onClick">
        <van-tab> -->
          <div class="tabs" v-if="tabType=='2'">
            <div class="tab-wrap">
              <span v-for="(item,index) in tabList" class="tab" :class="[item.active ? 'tabActive' : '']" :key="index" round @click="handdleClick(item,index)">
              {{item.name}}
              </span>
            <!-- <span class="tab active">非挂靠合同</span>
            <span class="tab">挂靠合同</span> -->
            </div>
          </div>
          <van-checkbox-group v-model="resultList" @change="handdleChange">
            <van-col class="contractCol" span="22" offset="1" v-for="(item,index) in list" :key="index" style="margin-top:10px;border-radius:5px;">
              <van-cell :center="true">
                <template #title>
                  <van-checkbox :name="item" ref="checkboxes" class="checkboxes" icon-size="15px" checked-color="#E50012">
                    <span class="custom-title">申请编号：{{item.tradeCode}}</span>
                    <span style="float:right;color: #3662EC;font-size:13px;">{{ item.status }}</span>
                  </van-checkbox>
                </template>
                <template #label>
                  <div @click="haddleCheck(index)">
                    <div class="cell-label">
                      <span class="custom-label">合同编码：</span>
                      <span class="custom-value">{{item.contractCode}}</span>
                    </div>
                    <div class="cell-label">
                      <span class="custom-label">产品名称：</span>
                      <span class="custom-value">{{item.productName}}</span>
                    </div>
                    <div class="cell-label">
                      <span class="custom-label">客户名称：</span>
                      <span class="custom-value">{{item.customerName}}</span>
                    </div>
                    <div class="cell-label">
                      <span class="custom-label">缴纳保证金期数：</span>
                      <span class="custom-value">{{item.rentNum}}</span>
                    </div>
                    <div style="margin-top: 20px;">
                      <span style="font-size: 12px;color:#333;">前置保证金：</span><span style="color:#E50012;font-size:15px;">{{ item.totalRent ||'--' }}元</span>
                      <span style="float:right;font-size: 12px;color: #666;" @click.stop="handdleShowPopup(index)">车辆详情<van-icon name="arrow" style="font-size: 14px;position: relative;top: 3px;margin-left: 4px;" /></span>
                    </div>
                  </div>
                </template>
              </van-cell>
            </van-col>
          </van-checkbox-group>
          <van-popup
            v-model="showPopup"
            closeable
            close-icon-position="top-left"
            position="bottom"
            round
            :style="{ height: '50%' }"
          >
            <div class="tipTitile">车辆详情</div>
            <van-cell-group class="popupCont">
              <van-cell title="品牌" :value="popupData.brandName" />
              <van-cell title="车型" :value="popupData.carKind" />
              <van-cell title="款式" :value="popupData.carStyle" />
              <van-cell title="车架号" :value="popupData.carVin" />
            </van-cell-group>
          </van-popup>
      <div class="btnBottom">
        <van-col span="20" offset="2">
          <van-button v-if="isReadonly" disabled round block color="#ddd" style="opacity:1">
          确定选择
          </van-button>
          <van-button v-if="!isReadonly" round block type="danger" @click="handdleSubmit">
          确定选择
          </van-button>
        </van-col>
      </div>
    <!-- </van-tab>
      </van-tabs> -->
    </div>
    <van-empty v-show='isEmpty'
      class="custom-image"
      :image="emptyImg"
      description="暂无数据"
    />
  </div>
  </div>
    
</template>
<script>
  import {getOpenId,getPersonPayContractList,confirmPersonPayContractList} from '@/server'
  import { Toast,Dialog } from 'vant';
  import Loading from "@/components/Loading";
  import {setCookie,getCookie,domainUrl,getBindStatus} from '@/lib/utils';
  export default {
    name: 'applyQuery',
    components: {
      Loading
    },
  data() {
    return {
      cardId:this.$store.state.cardId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      accessToken:this.$store.state.accessToken,
      finished: false,
      isLoading:true,
      isEmpty:false,
      emptyImg:require('@/assets/img/empty.png'),
      isReadonly:true,
      showPopup:false,
      popupData:{},
      resultList: [],
      checked:false,
      resData:{},
      list:[],
      tabType:'0',//0 仅有非挂靠，1仅有挂靠，2 两者都有
      isAffiliate:'2',//非1 非挂靠，1 挂靠 默认非挂靠
      tabList:[
        {name:'非挂靠合同',active:true},
        {name:'挂靠合同',active:false}
      ],
    };
  },
  mounted(){
    // if(sessionStorage.getItem("source")=='1'){//1 从付款信息
    //   sessionStorage.setItem("origin",'0')//0 重选后添加数据 1原始数据
    // }else{
    //   sessionStorage.setItem("origin",'1')
    // }
    // let that = this;
    // sessionStorage.setItem('key', 'advanceDeposit');
    
    // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    // 	if(sessionStorage.getItem('key') == 'advanceDeposit') {
    //     that.$router.push({ path: '/personalCenter' });
    //   }
    // }, false);
  },
  created(){
    getBindStatus(this.code,this.openId,this.cardId,this.accessToken,"/advanceDeposit",this.getDetail())
  },
  methods: {
    getDetail(){
      const params = {
        certId: this.cardId,
        type: "03",
        payInfoId:sessionStorage.getItem("payInfoId") || null
      }
      getPersonPayContractList({data:params}).then(res=>{
        const {data,status}=res
        if(status == '200'){
          this.isLoading = false;
          this.resData = data;
          if(sessionStorage.getItem("source")=='1'){
            this.tabType = '1';//仅有挂靠
            this.list = data.isAffiliateList;
          }else{
            this.tabType = data.tabType;
            if(data.tabType == '0'){//仅非挂靠
              if(data.isNotAffiliateList.length==0){
                this.isEmpty = true;
              }else{
                this.isEmpty = false;
                this.list = data.isNotAffiliateList;
                this.isAffiliate = '2';//非挂靠
              }
            }else if(data.tabType == '1'){//仅挂靠
              if(data.isAffiliateList.length==0){
                this.isEmpty = true;
              }else{
                this.isEmpty = false;
                this.list = data.isAffiliateList;
                this.isAffiliate = '1';
              }
            }else if(data.tabType == '2'){
              this.list = data.isNotAffiliateList;//默认非挂靠
            }else{
              this.isEmpty = true;
            }
          }
        }else{
          this.isLoading = false;
          Toast.fail({
            message: res.subMsg || res.msg,
            duration:'3000'
          });
        }
      })
    },
    handdleShowPopup(index){
      this.popupData = this.list[index];
      this.showPopup = true;
    },
    tabChange(item,index){
      item.active = true;
        this.tabList.forEach(v=>{
          if(v.name !=item.name){
          v.active = false
        }
        })
        this.resultList = [];
        this.isReadonly = false;
        if(index==0){//非挂靠
          this.list = this.resData.isNotAffiliateList;
          this.isAffiliate = '2';
        }else{
          this.list = this.resData.isAffiliateList;
          this.isAffiliate = '1';//挂靠
        }
    },
    handdleClick(item,index){
      if(this.resultList.length==0){
        this.tabChange(item,index)
      }else{
        Dialog.confirm({
          message: '如您选择切换合同类型，则已选择的付款合同将取消选中',
          confirmButtonText:'是',
          cancelButtonText:'否',
          confirmButtonColor:'#333',
          cancelButtonColor:'#999'
        })
        .then(() => {
          this.tabChange(item,index)
        })
        .catch(() => {
          // on cancel
        });
      }
      
    },
    handdleChange(value){
      if(value.length==0){
        this.isReadonly = true;
      }else{
        this.isReadonly = false;
      }
    },
    haddleCheck(index){
      this.$refs.checkboxes[index].toggle()
    },
    handdleSubmit(){
      const params = {
        isAffiliate:this.isAffiliate,
        payerId:this.openId,
        personList:this.resultList,
        certId:this.cardId,
        payInfoId:sessionStorage.getItem("payInfoId") || null,
        source:sessionStorage.getItem("source") || '0'//0 正常 ，1 从付款信息
      }
      confirmPersonPayContractList({data:params}).then(res=>{
        // this.$store.commit('updatedIsLoading', false);
        if(res.status == '200'){
          if(sessionStorage.getItem("source")=='1'){
            // sessionStorage.setItem("payInfoId",res.data);
            // sessionStorage.setItem("isAffiliate",this.isAffiliate);
            sessionStorage.setItem("origin",'0')//0 重选后添加数据 1原始数据
            this.$router.go(-1)
          }else{
            sessionStorage.setItem("origin",'1')//0 重选后添加数据 1原始数据
            this.$router.push({path: '/advanceDeposit/payment',query:{id:res.data,isAffiliate:this.isAffiliate}});
          }
        }else{
          Toast.fail({
            message: res.subMsg || res.msg,
            duration:'3000'
          });
        }
      })
    }
  },
};
</script>
<style>
  .checkboxes .van-checkbox__label{
    width: 100% !important;
  }
  .popupCont .van-cell{
    font-size: 15px !important;
  }
  .popupCont .van-cell__value{
    flex: 2 !important;
  }
</style>>
<style scoped lang="less">
  .wrap{
    /* padding: 20px 0px;  */
    overflow: hidden;
    min-height: 100vh !important;
    background: #F8F8F8;
    .wrap1{
      min-height: 100vh;
      // overflow: hidden;
      // padding-bottom: 120px;
      .contractCol:last-child{
        padding-bottom: 120px;
      }
    }
    .tabs{
      padding: 15px 10px;
      height: 35px;
      line-height: 35px;
      // width: 100%;
      background-color: #fff;
      .tab-wrap{
        background-color: #ECECEC;
        display: flex;
        border-radius: 2px;
        .tab{
          flex: 1;
          display: inline-block;
          text-align: center;
          margin: 5px;
          color: #888;
          cursor: pointer;
        }
        .tabActive{
          // margin: 5px;
          background-color: #fff;
          border-radius: 3px;
          color: #333;
        }
      }
      
    }
  }
  .btnBottom{
    position: fixed;
    width: 100%;
    background-color: #fff;
    bottom: 0px;
    padding-bottom: 15px;
    padding-top:10px;
  }
  .tipTitile{
    color: #1A1A1A;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #eee;
    height: 60px;
    line-height: 60px;
    /* margin-top: 10px; */
  }
  .cell-label{
    margin-top: 10px; 
    padding-left: 8px;
    width: 6rem;
    white-space: nowrap; /* 确保文本在一行内显示 */
    overflow: hidden; /* 隐藏溢出的内容 */
    text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
  }
  .custom-title{
    font-size:12px;
    color:#666;
  }
  .custom-label{
    color:#333333;
    font-size: 13px;
    font-weight: bold;
  }
  .custom-value{
    font-size: 13px;
    color:#333333;
  }
</style>